import React from "react";
import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.Container}>
            <p className={styles.message}>{t("Footer")} 😀</p>
        </div>
    );
};
