import styles from "./NavBar.module.scss";
import Logo from "./logo.png";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Button, Dropdown } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import { GlobeIcon } from "./GlobeIcon";

export const NavBar = () => {
    const { user, logout, isAuthenticated } = useAuth0();
    const { t, i18n } = useTranslation();

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <strong>{user?.name}</strong>
            </Menu.Item>
            <Menu.Item key="2">{user?.email}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                <Button
                    shape="round"
                    size="large"
                    className={styles.LogOutBtn}
                    onClick={() => logout({ returnTo: window.location.origin })}
                >
                    {t("Logout")}
                </Button>
            </Menu.Item>
        </Menu>
    );

    const menu2 = (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => {
                    i18n.changeLanguage("en");
                }}
            >
                ENG &nbsp;
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="2"
                onClick={() => {
                    i18n.changeLanguage("de");
                }}
            >
                DEU &nbsp;
            </Menu.Item>
        </Menu>
    );

    return (
        <div className={styles.Container}>
            <Link to="/">
                <img src={Logo} className={styles.logo} />
            </Link>
            <div className={styles.RightChild}>
                {isAuthenticated && (
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <div className={styles.User} style={{ backgroundImage: `url(${user?.picture})` }}></div>
                    </Dropdown>
                )}

                <Dropdown overlay={menu2} trigger={["click"]}>
                    <div className={styles.GlobeIcon}>
                        <GlobeIcon />
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};
