import axios from "axios";

export const fetchData = async ({ Street, HouseNo, City, PostalCode, Info }) => {
    let config = {
        headers: {
            "x-iib-token": process.env.REACT_APP_IIB_TOKEN_HEADER || window.env.REACT_APP_IIB_TOKEN_HEADER,
        },
        params: {
            street_name: Street,
            house_number: HouseNo,
            commune: City,
            postal_code: PostalCode,
            additional_info: Info,
        },
    };

    const data = axios
        .get("https://wally.iib-it.de/geocoder/", config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("ERROR" + error);
            return {};
        });

    return await data;
};

export const cleanData = (Data) => {
    if (Data?.latitude === null || Data?.longitude == null) return [];
    if (Data?.source === "here-api") {
        return Data?.full_payload?.items;
    } else if (Data?.source === "internal-db") {
        return [
            {
                position: { lat: Data.latitude, lng: Data.longitude },
                neighborhood: Data?.neighborhood,
            },
        ];
    }
    return [];
};
