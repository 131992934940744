import { useEffect, useState } from "react";
import { Footer } from "./components/Footer/Footer";
import { NavBar } from "./components/NavBar/NavBar";
import { Home } from "./pages/Home/Home";
import * as Sentry from "@sentry/react";
import { Routes, Route, Navigate } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import LockScreen from "react-lock-screen";
import { Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./App.module.scss";
import { KEY, ROLE } from "./utils/constants";

const LoadIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const ConditionalWrap = ({ condition, wrap, children }) => (condition ? wrap(children) : children);

const getLockScreenUi = () => {
    return (
        <div className={styles.MessageContainer}>
            <Alert
                className={styles.Message}
                message="Notice"
                description="Please contact info@iib-institut.de if you would like access to this service ( Bitte kontaktieren Sie info@iib-institut.de wenn Sie Zugang zu diesem Service möchten )"
                type="warning"
                showIcon
            />
        </div>
    );
};

function App() {
    const { user, isAuthenticated } = useAuth0();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            if (user[KEY].includes(ROLE) == false) setShowError(true);
        }
    }, [user]);

    return (
        <>
            <ConditionalWrap
                condition={showError}
                wrap={(children) => (
                    <LockScreen className={styles.reactLockScreen} timeout={0} ui={getLockScreenUi}>
                        {children}
                    </LockScreen>
                )}
            >
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home error={showError} />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />
            </ConditionalWrap>
        </>
    );
}

export default withAuthenticationRequired(Sentry.withProfiler(App), {
    onRedirecting: () => (
        <div className={styles.SpinnerContainer}>
            <Spin className={styles.Spinner} size="large" tip="Loading..." indicator={LoadIcon} />
        </div>
    ),
});
