import { Modal } from "antd";
import styles from "./MyModal.module.scss";

export const MyModal = ({ title, children, isModalVisible, setIsModalVisible }) => {
    return (
        <>
            <Modal
                title={title}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                className={styles.Modal}
            >
                {children}
            </Modal>
        </>
    );
};
