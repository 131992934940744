import styles from "./ShowData.module.scss";
import React from "react";
import ReactJson from "react-json-view";

export const ShowData = ({ Data }) => {
    return (
        <div className={styles.Container}>
            <ReactJson
                src={Data}
                indentWidth={2}
                name={false}
                displayObjectSize={true}
                displayDataTypes={false}
                iconStyle={"circle"}
                collapseStringsAfterLength={20}
                collapsed={1}
            />
        </div>
    );
};

export default ShowData;
