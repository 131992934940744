import { useAuth0 } from "@auth0/auth0-react";
import styles from "./Map.module.css";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { MapboxExportControl, Size, PageOrientation, Format, DPI } from "@watergis/mapbox-gl-export";
import "@watergis/mapbox-gl-export/css/styles.css";
import ReactMapGL, { ScaleControl, NavigationControl, FlyToInterpolator } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pins from "./Pins.jsx";

// // added the following 6 lines to solve the map issue in build production ( this is a bug reported by react-map-gl themselves at https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling )
import mapboxgl from "mapbox-gl";

// // The following is required to stop "npm build" from transpiling mapbox code.
// // notice the exclamation point in the import.
// // @ts-ignore
// // eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const scaleControlStyle = {
    bottom: 36,
    right: 0,
    padding: "10px",
};

const navStyle = {
    bottom: 70,
    right: 0,
    padding: "10px",
};

export const Map = ({ Places }) => {
    const [viewport, setViewport] = useState({});
    const [prevPlaces, setprevPlaces] = useState([]);
    const mapRef = useRef();
    const [popupInfo, setPopupInfo] = useState(null);
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        mapRef.current = isAuthenticated ? mapRef.current.getMap() : mapRef.current;
        if (isAuthenticated) {
            mapRef.current.addControl(
                new MapboxExportControl({
                    PageSize: Size.A3,
                    PageOrientation: PageOrientation.Portrait,
                    Format: Format.PNG,
                    DPI: DPI[96],
                    Crosshair: true,
                    PrintableArea: true,
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN || window.env.REACT_APP_MAPBOX_TOKEN,
                }),
                "top-right"
            );
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setPopupInfo(null);

        setViewport((viewport) => ({
            ...viewport,
            latitude: 51.5,
            longitude: 10.5,
            zoom: 5.5,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
            transitionDuration: "auto",
        }));

        //removes the previous layers of points
        prevPlaces?.forEach((x, i) => {
            mapRef.current = mapRef.current.removeLayer(`point${i}`);
            mapRef.current = mapRef.current.removeSource(`point${i}`);
        });

        setprevPlaces(Places);

        //add new layers of points
        Places?.forEach((x, i) => {
            mapRef.current = mapRef.current.addSource(`point${i}`, {
                type: "geojson",
                data: {
                    type: "Point",
                    coordinates: [x.position.lng, x.position.lat],
                },
            });

            mapRef.current = mapRef.current.addLayer({
                id: `point${i}`,
                source: `point${i}`,
                type: "circle",
                paint: {
                    "circle-radius": 8,
                    "circle-color": "red",
                    "circle-stroke-width": 1,
                    "circle-blur": 0.5,
                },
            });
        });
    }, [Places]);

    const onSelectPlace = useCallback((position, address, neighborhood) => {
        let { label = null } = address || true;
        if (label == null) {
            label = neighborhood || " ";
        }

        let { lat, lng } = position;

        setViewport((viewport) => ({
            ...viewport,
            longitude: lng,
            latitude: lat,
            zoom: 16,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
            transitionDuration: "auto",
        }));

        setPopupInfo({ lat, lng, label });
    }, []);

    return (
        <div className={styles.Container}>
            <ReactMapGL
                {...viewport}
                ref={mapRef}
                width="100%"
                height="100%"
                onViewportChange={setViewport}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN || window.env.REACT_APP_MAPBOX_TOKEN}
                dragRotate={false}
                mapStyle="mapbox://styles/mapbox/streets-v10"
                preserveDrawingBuffer={true}
            >
                <Pins data={Places} onClick={onSelectPlace} popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
                <ScaleControl style={scaleControlStyle} />
                <NavigationControl style={navStyle} />
            </ReactMapGL>
        </div>
    );
};
