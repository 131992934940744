import styles from "./InformationGetter.module.scss";
import "antd/dist/antd.css";
import { Input } from "antd";
import { Button } from "antd";
import { useState, useEffect } from "react";
import { fetchData } from "../utils/functions";
import { useTranslation } from "react-i18next";

export const InformationGetter = ({ setData, error }) => {
    const [Street, setStreet] = useState("");
    const [HouseNo, setHouseNo] = useState("");
    const [City, setCity] = useState("");
    const [PostalCode, setPostalCode] = useState("");
    const [Info, setInfo] = useState("");

    const [disabled, setDisabled] = useState(true);

    const { t } = useTranslation("form", { keyPrefix: "form" });

    useEffect(() => {
        setDisabled(!((Street.length && HouseNo.length) || (City.length && PostalCode.length)));
    }, [Street, HouseNo, City, PostalCode]);

    async function handleSubmit() {
        let data = await fetchData({ Street, HouseNo, City, PostalCode, Info });
        setData(data.data);
    }

    return (
        <div className={styles.Container}>
            <div className={styles.content}>
                <div className={styles.Title}>wanda.iib-it.de</div>
                <div className={styles.inputs}>
                    <div className={styles.input}>
                        <Input
                            placeholder={t("Street")}
                            size="middle"
                            value={Street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                    </div>
                    <div className={styles.input}>
                        <Input
                            placeholder={t("House")}
                            size="middle"
                            value={HouseNo}
                            onChange={(e) => setHouseNo(e.target.value)}
                        />
                    </div>
                    <div className={styles.input}>
                        <Input
                            placeholder={t("City")}
                            size="middle"
                            value={City}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className={styles.input}>
                        <Input
                            placeholder={t("Postal")}
                            size="middle"
                            value={PostalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                        />
                    </div>
                    <div className={styles.input}>
                        <Input
                            placeholder={t("Additional")}
                            size="middle"
                            value={Info}
                            onChange={(e) => setInfo(e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    className={!disabled ? styles.Btn : styles.BtnDisabled}
                    type="primary"
                    onClick={handleSubmit}
                    disabled={disabled || error}
                    title={"Click to Call Wally"}
                >
                    {t("Call")} Wally
                </Button>
            </div>
        </div>
    );
};
