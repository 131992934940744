import React from "react";
import styles from "./Home.module.scss";
import { useState, useEffect } from "react";
import { InformationGetter } from "../../components/InformationGetter/InformationGetter";
import { ShowData } from "../../components/ShowData/ShowData";
import { Map } from "../../components/Map/Map";
import { Faqs } from "../../components/Faqs/Faqs";
import { cleanData } from "../../components/utils/functions";
import "antd/dist/antd.css";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Layout, Button } from "antd";
import { MyModal } from "../../components/Modal/MyModal";
import { useWindowSize } from "../../hooks/useWindowSize";

const { Header, Sider, Content } = Layout;

export const Home = ({ error }) => {
    const [Data, setData] = useState({});
    const [Places, setPlaces] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    useEffect(() => {
        let places = cleanData(Data, setPlaces);
        setPlaces(places);
    }, [Data]);

    const { width } = useWindowSize();
    useEffect(() => {
        width > 400 ? setCollapsed(false) : setCollapsed(true);
    }, [width]);

    return (
        <>
            <Layout className={styles.Container}>
                <Sider
                    trigger={null}
                    collapsedWidth={0}
                    collapsible
                    collapsed={collapsed}
                    className={styles.Sider}
                    width={width > 600 ? 380 : 280}
                >
                    <div className={styles.SiderChildren}>
                        <InformationGetter setData={setData} error={error} />
                        <Button type="primary" onClick={() => setIsModalVisible1(true)} className={styles.Button}>
                            Show Api
                        </Button>
                        <MyModal title="Data" isModalVisible={isModalVisible1} setIsModalVisible={setIsModalVisible1}>
                            <ShowData Data={Data} />
                        </MyModal>
                        <font onClick={() => setIsModalVisible2(true)} className={styles.Link}>
                            See FAQS
                        </font>
                        <MyModal title="FAQS" isModalVisible={isModalVisible2} setIsModalVisible={setIsModalVisible2}>
                            <Faqs />
                        </MyModal>
                    </div>
                </Sider>
                <Layout>
                    <Header className={styles.Header} style={{ padding: 0 }}>
                        <div className={styles.icon} onClick={() => setCollapsed((s) => !s)}>
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </div>
                    </Header>
                    <Content className={styles.MapContainer}>
                        <Map Places={Places} />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
