import Faq from "react-faq-component";
import { useTranslation } from "react-i18next";

export const Faqs = () => {
    const { t } = useTranslation("Faqs", { keyPrefix: "Faqs" });

    const data = {
        title: t("Title"),
        rows: [
            {
                title: t("heading1"),
                content: t("content1"),
            },
            {
                title: t("heading2"),
                content: t("content2"),
            },
            {
                title: t("heading3"),
                content: t("content3"),
            },
            {
                title: t("heading4"),
                content: <p>{t("content4")}</p>,
            },
        ],
    };

    return (
        <div>
            <div>
                <Faq data={data} />
            </div>
        </div>
    );
};
